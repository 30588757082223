<template>
  <el-row>
    <formForList v-for="form in forms" v-bind:form="form" v-bind:key="form.workflow_id"/>
  </el-row>
</template>
<script>
import formForList from "@/components/formForList.vue";
import { Token } from "../main.js";
export default {
  components: {
    formForList
  },
  data() {
    return {
      forms: []
    };
  },
  methods: {
  },
  mounted: function() {
    $.ajax({
      url:
        "https://gateway.cycloptux.com/api/v1/bots/" +
        this.$store.getters.bot_selected_id +
        "/servers/" +
        this.$store.getters.server_selected_id +
        "/modules/forms",
      type: "GET",
      data: "{}",
      dataType: "json",
      headers: { Authorization: "Bearer " + Token.token }
    }).done(data => {
      this.forms = data.forms;
    });
  }
};
</script>

<style scoped>

</style>