<template>
<div class="fullPageBotContainer">
<el-row class="riga" type="flex" justify="center">
 <botForSel v-for="bot in bots" v-bind:bot="bot" v-bind:key="bot.id" class="grow"></botForSel>
 </el-row>
 </div>
</template>
<script>
import botForSel from "@/components/botForSel.vue";
import { Token } from "../main.js";
export default {
  components: {
    botForSel
  },
  data() {
    return {
      bots: []
    };
  },
  mounted: function() {
    $.ajax({
      url: "https://gateway.cycloptux.com/api/v1/bots",
      type: "GET",
      data: "{}",
      dataType: "json",
      headers: { Authorization: "Bearer " + Token.token }
    }).done(data => {
      this.bots=data.bots;
    });
  }
};
</script>
<style scoped>
.fullPageBotContainer{
    background-color: #23272A;
    width:100vw;
    height:100vh;
}
.riga{
		top:50%;
        transform:translate3d(0,-50%,0);
}
.grow { 
    transition: all .2s ease-in-out;
     }
.grow:hover {
     transform: scale(1.1); 
}
</style>