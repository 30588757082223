import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export const store = new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    bot_selected :{},
    server_selected : {}
  },
  mutations: {
    changeBot(state, new_bot) {
      state.bot_selected = new_bot
    },
    changeServer(state, new_server) {
      state.server_selected = new_server
    },
  },
  getters: {
    bot_selected: state => state.bot_selected,
    server_selected: state => state.server_selected,
    bot_selected_id: state => state.bot_selected.id,
    server_selected_id: state => state.server_selected.id
  }
})