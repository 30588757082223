<template>
  <el-col :span="6" class="column">
    <div v-if="module.available" class="card">
      <img
        :src="'~@/assets/modules_logos/' + module.img"
        style="width:128px;height:auto;padding-top:20px"
      />
      <h1>{{module.name}}</h1>
      <p class="title">{{module.full_name}}</p>
      <p>{{module.desc}}</p>

      <p>
        <router-link :to="{ name: module.name}"><button>Settings</button></router-link>
      </p>
    </div>

    <div v-else class="card locked">
      <img
        :src="'~@/assets/modules_logos/' + module.img"
        style="width:128px;height:auto;padding-top:20px"
      />
      <h1>{{module.name}}</h1>
      <p class="title">{{module.full_name}}</p>
      <p>{{module.desc}}</p>

      <p>
        <button class="btnLocked">
         <label>Locked</label>
        </button>
      </p>
    </div>
  </el-col>
</template>

<script>
export default {
  name: "Module",
  props: {
    module: Object
  }
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}

.btnLocked:hover label{
  display: none;
}

.btnLocked:hover:before {content:" Unlock"}

.locked {
  background-color: #c8c8c8 !important;
}

.column {
  padding: 10px 10px;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

@media screen and (max-width: 600px) {
  .column {
    width: 100%;
    display: block;
    margin-bottom: 20px;
  }
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  max-width: 300px;
  margin: auto;
  background-color: white;
  text-align: center;
  border-radius: 40px;
  padding-bottom: 15px;
}

.title {
  color: gray;
  font-size: 18px;
}

button {
  border: none;
  padding: 8px;
  display: inline-block;
  color: white;
  background-color: #2c2f33;
  cursor: pointer;
  width: 70%;
  border-radius: 20px;
  font-size: 18px;
  text-align: center;
}

.card h1 {
  color: #2c2f33;
}

a:hover,
button:hover {
  opacity: 0.8;
}
</style>
