<template>
  <nav class="menu" tabindex="0">
    <div class="smartphone-menu-trigger"></div>
    <header class="avatar">
      <img
        :src="'https://cdn.discordapp.com/avatars/' + selected_bot.id + '/' + selected_bot.icon + '.png'"
      />
      <h2>{{selected_bot.name}}</h2>
      
    </header>
    <div style="text-align:center">
    <ddlServers />
    </div>
    <ul>
      <li tabindex="0" class="icon-stats">
        <span>Stats</span>
      </li>
      <li tabindex="0" class="icon-modules">
        <router-link :to="{ name: 'modulesList'}"><span>Modules</span></router-link>
      </li>
      <li tabindex="0" class="icon-logs">
        <router-link :to="{ name: 'logs'}"><span>Logs</span></router-link>
      </li>
      <li tabindex="0" class="icon-settings">
        <span>Global settings</span>
      </li>
    </ul>
  </nav>
</template>

<script>
import ddlServers from "@/components/ddlServers.vue";
export default {
  components: {
    ddlServers
  },
  data() {
    return {};
  },
  computed: {
    selected_bot() {
      return this.$store.getters.bot_selected;
    }
  }
};
</script>
<style>
a {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}
.menu {
  background: #2c2f33;
  height: 100vh;
  width: 240px;
  top: 0;
  left: 0;
  z-index: 5;
  outline: none;
  font-family: "Open Sans", Helvetica Neue, Helvetica, Arial, sans-serif;
  color: #fff;
}
.menu .avatar {
  background: rgba(0, 0, 0, 0.1);
  padding: 2em 0.5em;
  text-align: center;
}
.menu .avatar img {
  width: 100px;
  border-radius: 50%;
  overflow: hidden;
  border: 4px solid #7289da;
  box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.2);
}
.menu .avatar h2 {
  font-weight: normal;
  margin-bottom: 0;
}
.menu ul {
  list-style: none;
  padding: 0.5em 0;
  margin: 0;
}
.menu ul li {
  padding: 0.5em 1em 0.5em 3em;
  font-size: 0.95em;
  font-weight: regular;
  background-repeat: no-repeat;
  background-position: left 15px center;
  background-size: auto 20px;
  transition: all 0.15s linear;
  cursor: pointer;
}
.menu ul li.icon-stats {
  background-image: url("../assets/icons/char_bar.svg");
}
.menu ul li.icon-modules {
  background-image: url("../assets/icons/modules.svg");
}
.menu ul li.icon-logs {
  background-image: url("../assets/icons/clock.svg");
}
.menu ul li.icon-settings {
  background-image: url("../assets/icons/gear.svg");
}
.menu ul li:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.menu ul li:focus {
  outline: none;
}
@media screen and (max-width: 900px) and (min-width: 400px) {
  body {
    padding-left: 90px;
  }
  .menu {
    width: 90px;
    position: fixed;
  }
  .menu .avatar {
    padding: 0.5em;
    position: relative;
  }
  .menu .avatar img {
    width: 60px;
  }
  .menu .avatar h2 {
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 100px;
    margin: 0;
    min-width: 200px;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.4);
    transform: translate3d(-20px, -50%, 0);
  }
  .menu .avatar:hover h2 {
    opacity: 1;
    transform: translate3d(0px, -50%, 0);
  }
  .menu ul li {
    height: 60px;
    background-position: center center;
    background-size: 30px auto;
    position: relative;
  }
  .menu ul li span {
    opacity: 0;
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    padding: 0.2em 0.5em;
    border-radius: 4px;
    top: 50%;
    left: 80px;
    transform: translate3d(-15px, -50%, 0);
  }
  .menu ul li span:before {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    top: 50%;
    left: -5px;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 5px solid rgba(0, 0, 0, 0.5);
    transform: translateY(-50%);
  }
  .menu ul li:hover span {
    opacity: 1;
    transform: translate3d(0px, -50%, 0);
  }
}
@media screen and (max-width: 400px) {
  body {
    padding-left: 0;
  }
  .menu {
    width: 230px;
    box-shadow: 0 0 0 100em rgba(0, 0, 0, 0);
    transform: translate3d(-230px, 0, 0);
    transition: all 0.3s ease-in-out;
    position: fixed;
  }
  .menu .smartphone-menu-trigger {
    width: 40px;
    height: 40px;
    position: absolute;
    left: 100%;
    background: #2c2f33;
    background-image: url("../assets/icons/burger.svg");
    background-repeat: no-repeat;
    background-size: 20px auto;
    background-position: center center;
  }

  .menu ul li {
    padding: 1em 1em 1em 3em;
    font-size: 1.2em;
  }
  .menu:focus {
    transform: translate3d(0, 0, 0);
    box-shadow: 0 0 0 100em rgba(0, 0, 0, 0.6);
  }
  .menu:focus .smartphone-menu-trigger {
    pointer-events: none;
  }
}
</style>