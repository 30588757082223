<template>
  <div>
    <el-dialog title="New answer" :visible.sync="newAnswerVisible" width="50%">
      <div class="d-block">
        <el-row>
          <el-col :span="24" style="padding-bottom:5px">
            <toggle-button
              v-model="new_aswer_data.isMessage"
              :width="140"
              :height="30"
              :font-size="20"
              :labels="{checked: 'Message', unchecked: 'Reaction'}"
              :color="{checked: '#be91eb', unchecked: '#c0eb91'}"
              style="float:left;"
            />
          </el-col>
        </el-row>
        <el-input type="textarea" rows="5" v-model="new_aswer_data.toSend.string"/>
        <div v-if="new_aswer_data.isMessage" class="message" style="margin-top:10px;">
          <el-row>
            <el-col :span="24" style="text-align:center; padding-top:20px;">Char limit:</el-col>
            <el-col :span="24" style="padding-left:20px; padding-right:20px">
              <vue-slider
                v-model="new_aswer_data.toSend.char_limit"
                :min="0"
                :max="2000"
                :interval="10"
                :enable-cross="false"
              />
            </el-col>
            <el-col
              :span="24"  style="text-align:center; padding-bottom:20px;"
            >Min: {{new_aswer_data.toSend.char_limit[0]}} - Max: {{new_aswer_data.toSend.char_limit[1]}}</el-col>
          </el-row>
        </div>
        <div
          v-if="!new_aswer_data.isMessage"
          class="reaction row"
          style="margin-top:10px;text-align:left;"
        >
          <ul>
            <li
              v-for="(option, optIndex) in new_aswer_data.toSend.answers_validation"
              :key="option"
            >
              {{option}}
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                style="margin:2px;"
                circle
                v-on:click="removeNewAnswerOption(optIndex)"
              ></el-button>
            </li>
            <li>
              <div style="display:flex;">
                <input class="el-input__inner">
                <el-button
                  type="primary"
                  style="margin-left:2px;"
                  v-on:click="addNewAnswerOption($event)"
                >+</el-button>
              </div>
            </li>
          </ul>
        </div>
        <el-row :gutter="20">
          <el-col :md="12" style="margin-top:10px">
            <label>alias</label>
            <el-input v-model="new_aswer_data.toSend.alias"/>
          </el-col>
          <el-col :md="12" style="margin-top:10px">
            <label>trello map</label>
            <el-select
              v-model="new_aswer_data.toSend.trello_mapping"
              placeholder="select trello map"
              style="width:100%"
            >
              <el-option
                v-for="trelloOpt in resp.trello_options"
                :key="trelloOpt"
                :value="trelloOpt"
                :label="trelloOpt"
              ></el-option>
            </el-select>
          </el-col>
        </el-row>
      </div>
      <el-row style="margin-top:20px">
        <el-col :span="24" style="padding:5px">
          <el-button type="success" style="width:100%;" plain @click="addNewAnswer()">Add Answer</el-button>
        </el-col>
        <el-col :span="24" style="padding:5px">
          <el-button type="info" style="width:100%;" plain @click="newAnswerVisible = false">Exit</el-button>
        </el-col>
      </el-row>
    </el-dialog>

    <el-row>
      <el-col :span="24" class="headOpt">
        <el-card style="overflow:unset;">
          <el-row>
            <el-col :md="12" style="padding-right:10px">
              <el-input placeholder="Please input" v-model="resp.title"></el-input>
            </el-col>
            <el-col :md="12" style="padding-left:10px">
<el-select style="width:100%" :disabled="!resp.trello_integration" placeholder="Select trello board" v-model="resp.trello_board">
                    <el-option
                      v-for="board in resp.trello_boards"
                      :key="board.id"
                      :value="board.id"
                      :label="board.name"
                    ></el-option>
                  </el-select>
</el-col>
          </el-row>
           <el-row class="pTop20">
            <el-col :md="6" class="mainSettingChk">
              <el-col :span="24">
                  <verte
                    v-model="resp.color_hex"
                    picker="square"
                    model="hex"
                    :enableAlpha="null"
                    :draggable="null"
                    :showHistory="null"
                  ></verte>
                </el-col>
              <el-col :span="24" style="text-align:center;">
                <label
                  style="line-height: 19px; font-size: 14px; color: #606266;"
                >hex color ({{resp.color_hex}})</label>
              </el-col>
            </el-col>
            <el-col :md="6" class="mainSettingChk">
              <el-checkbox v-model="resp.trello_integration">trello integration</el-checkbox>
            </el-col>
            <el-col :md="6" class="mainSettingChk">
              <el-checkbox v-model="resp.disable_upvote">disable upvote</el-checkbox>
            </el-col>
            <el-col :md="6" class="mainSettingChk">
              <el-checkbox v-model="resp.disable_downvote">disable downvote</el-checkbox>
            </el-col>
            <el-col :md="6" class="mainSettingChk">
              <el-checkbox v-model="resp.highlight_attachments">highlight attachments</el-checkbox>
            </el-col>
            <el-col :md="6" class="mainSettingChk">
              <el-checkbox v-model="resp.extended_embed">extended embed</el-checkbox>
            </el-col>
            <el-col :md="6" class="mainSettingChk">
              <el-checkbox v-model="resp.anonymize">anonymize</el-checkbox>
            </el-col>

          </el-row>
        </el-card>
      </el-col>
      <el-col :span="24" style="text-align:center;">
      <el-button type="primary" @click="newAnswerVisible = true" round>Add answer</el-button>
      <el-button type="success" round>Save all</el-button>
      </el-col>
      <el-col :span="24" class="headOpt">
        <draggable
          class="list-group"
          tag="ul"
          v-model="resp.steps"
          v-bind="dragOptions"
          handle=".handle"
        >
          <transition-group type="transition">
            <el-card
              class="list-group-item"
              v-bind:style=" element.type == 'message' ?
               'background:linear-gradient(45deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 85%, rgba(190,145,235,1) 100%);' : 
               'background: linear-gradient(45deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 85%, rgba(192,235,145,1) 100%);'"
              v-for="(element, listIndex) in resp.steps"
              :key="element.step_id"
            >
              <i
                class="el-icon-menu handle"
                style="position:absolute; top:8px; left:6px; height:40px; width:40px;"
              ></i>
              <el-button
                type="text"
                style="position:absolute; top:0; right:0; height:40px; width:40px;"
                v-on:click="removeQuest(listIndex)"
              >X</el-button>
              <el-input type="textarea" rows="4"  style="margin-top:15px;" v-model="element.string"/>

              <div v-if="element.type == 'message'" class="message row" style="margin-top:10px;">
                <el-col :span="24" style="text-align:center; padding-top:20px;">Char limit:</el-col>
                <el-col :span="24">
                  <vue-slider
                    v-model="element.char_limit"
                    :min="0"
                    :max="2000"
                    :interval="10"
                    :enable-cross="false"
                  />
                </el-col>
                <el-col :span="24" style="text-align:center; padding-bottom:20px;">Min: {{element.char_limit[0]}} - Max: {{element.char_limit[1]}}</el-col>
              </div>
              <div
                v-if="element.type == 'reaction'"
                class="reaction row"
                style="margin-top:10px;text-align:left;"
              >
                <ul>
                  <li v-for="(option, optIndex) in element.answers_validation" :key="option">
                    {{option}}
                    <el-button
                      type="danger"
                      icon="el-icon-delete"
                      size="mini"
                      style="margin:2px;"
                      circle
                      v-on:click="removeOption(listIndex, optIndex)"
                    ></el-button>
                  </li>
                  <li style="padding-top:10px;">
                    <div style="display:flex;">
                      <input class="el-input__inner">
                      <el-button
                        type="primary"
                        style="margin-left:2px;"
                        v-on:click="addOption(listIndex, $event)"
                      >+</el-button>
                    </div>
                  </li>
                </ul>
              </div>
              <el-row :gutter="20" style="padding-top:10px;">
                <el-col :md="12" style="margin-top:10px;">
                  <label>alias</label>
                  <el-input v-model="element.alias"/>
                </el-col>
                <el-col :md="12" style="margin-top:10px;">
                  <label>trello map</label>
                  <el-select style="width:100%" v-model="element.trello_mapping">
                    <el-option
                      v-for="trelloOpt in resp.trello_options"
                      :key="trelloOpt"
                      :value="trelloOpt"
                      :label="trelloOpt"
                    ></el-option>
                  </el-select>
                </el-col>
              </el-row>
            </el-card>
          </transition-group>
        </draggable>
      </el-col>

      <!--<el-col :span="24">{{ resp }}</el-col>-->
    </el-row>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/antd.css";
import verte from "verte";
import "verte/dist/verte.css";
import { ToggleButton } from "vue-js-toggle-button";

import { Token } from "../main.js";

export default {
  name: "form",
  components: {
    draggable,
    VueSlider,
    verte,
    ToggleButton
  },
  props:{
    formId: Number
  },
  data() {
    return {
      newAnswerVisible: false,
      lastInsAnsId: 0,
      new_aswer_data: {
        isMessage: true,
        toSend: {
          step_id: null,
          type: null,
          string: null,
          answers_validation: [],
          char_limit: [0, 2000],
          alias: null,
          trello_mapping: null
        }
      },
      resp: {
      }
    };
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    }
  },
  methods: {
    addOption: function(listIndex, event) {
      var newAnser = event.target.parentNode.childNodes[0].value;
      if (newAnser) {
        this.resp.steps[listIndex].answers_validation.push(newAnser);
        event.target.parentNode.childNodes[0].value = null;
      }
    },
    removeOption: function(listIndex, optIndex) {
      this.resp.steps[listIndex].answers_validation.splice(optIndex, 1);
    },
    removeQuest: function(listIndex) {
      this.resp.steps.splice(listIndex, 1);
    },
    addNewAnswerOption: function(event) {
      var newAnser = event.target.parentNode.childNodes[0].value;
      if (newAnser) {
        this.new_aswer_data.toSend.answers_validation.push(newAnser);
        event.target.parentNode.childNodes[0].value = null;
      }
    },
    removeNewAnswerOption: function(optIndex) {
      this.new_aswer_data.toSend.answers_validation.splice(optIndex, 1);
    },
    addNewAnswer: function() {
      this.new_aswer_data.toSend.step_id = "n" + (this.lastInsAnsId + 1);
      this.lastInsAnsId++;
      if (this.new_aswer_data.isMessage) {
        this.new_aswer_data.toSend.type = "message";
      } else {
        this.new_aswer_data.toSend.type = "reaction";
      }
      this.resp.steps.push(this.new_aswer_data.toSend);
      this.new_aswer_data.toSend = {
        step_id: null,
        type: null,
        string: null,
        answers_validation: [],
        char_limit: [0, 2000],
        alias: null,
        trello_mapping: null
      };
    }
  },
  mounted: function() {
    $.ajax({
      url:
        "https://gateway.cycloptux.com/api/v1/bots/" +
        this.$store.getters.bot_selected_id +
        "/servers/" +
        this.$store.getters.server_selected_id +
        "/modules/forms/" + this.formId,
      type: "GET",
      data: "{}",
      dataType: "json",
      headers: { Authorization: "Bearer " + Token.token }
    }).done(data => {
      this.resp = data;
    });
  }
};
</script>
<style>
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-group {
  min-height: 20px;
}
.list-group-item {
  position: relative;
}
.list-group-item i {
  cursor: pointer;
}
.headOpt {
  padding: 30px;
}
.pTop20 {
  padding-top: 20px;
}
.custom-checkbox {
  display: inline;
}
ul.list-group{
    padding:0px;
    margin:0px;
}
</style>