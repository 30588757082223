<template>
  <el-table height="100%" :data="logs" empty-text="no logs" style="width: 100%;height: 100%;">
    <el-table-column label="Date" width="180">
      <template slot-scope="scope">
        <i class="el-icon-time"></i>
        <span style="margin-left: 10px">{{ parseTimestamp(scope.row.timestamp) }}</span>
      </template>
    </el-table-column>
    <el-table-column label="User" width="180">
      <template slot-scope="scope">
        <el-popover placement="right-start" close-delay="0" width="200" trigger="hover">
          <el-card :body-style="{ padding: '0px' }">
            <img
              :src="'https://cdn.discordapp.com/avatars/' + scope.row.user_id + '/' + scope.row.user_avatar + '.png'"
              class="image"
              @error="imageLoadOnError"
            />
            <div style="padding: 14px;">
              <span>{{scope.row.user_name}}</span>
              <div class="bottom clearfix">
                <time class="time">{{scope.row.user_id}}</time>
              </div>
            </div>
          </el-card>
          <el-button
            v-on:click="copyToClipboard(scope.row.user_id)"
            slot="reference"
            style="margin-left: 10px"
          >{{scope.row.user_name}}</el-button>
        </el-popover>
      </template>
    </el-table-column>
    <el-table-column prop="event_string" label="Description"></el-table-column>
    <el-table-column prop="module_name" label="Module" width="180"></el-table-column>
  </el-table>
</template>
<script>
import { Token } from "../main.js";
export default {
  components: {
    module
  },
  data() {
    return {
      logs: []
    };
  },
  methods: {
    imageLoadOnError(e) {
      e.target.src = require("../assets/error.png");
    },
    parseTimestamp(date) {
      return moment(String(date)).format("DD/MM/YYYY hh:mm:ss");
    },
    copyToClipboard(text) {
      var dummy = document.createElement("textarea");
      // to avoid breaking orgain page when copying more words
      // cant copy when adding below this code
      // dummy.style.display = 'none'
      document.body.appendChild(dummy);
      //Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
      dummy.value = text;
      dummy.select();
      document.execCommand("copy");
      document.body.removeChild(dummy);
    }
  },
  mounted: function() {
    $.ajax({
      url:
        "https://gateway.cycloptux.com/api/v1/bots/" +
        this.$store.getters.bot_selected_id +
        "/servers/" +
        this.$store.getters.server_selected_id +
        "/logs",
      type: "POST",
      data: "{}",
      dataType: "json",
      headers: { Authorization: "Bearer " + Token.token }
    }).done(data => {
      this.logs = data.logs;
    });
  }
};
</script>

<style scoped>
.time {
  font-size: 13px;
  color: #999;
}

.bottom {
  margin-top: 13px;
  line-height: 12px;
}

.button {
  padding: 0;
  float: right;
}

.image {
  width: 100%;
  display: block;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}
</style>