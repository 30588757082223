<template>
  <el-container>
    <el-aside>
        <menuLeft>
        </menuLeft>
    </el-aside>
  </el-container>
</template>
<script>
import menuLeft from "@/components/menuLeft.vue";
import { Token } from "../main.js";
export default {
  components: {
    menuLeft
  },
  data() {
    return {
      
    };
  },
  mounted: function() {
    
  }
};
</script>
