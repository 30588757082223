<template>
  <div class="home">
    <div class="jumbotron jumbotron-fluid">
      <div class="container">
        <h1 class="display-4">Welcome</h1>
        <p class="lead">To the bots dashboard</p>
        <button v-on:click="startAuth()" class="btn btn-info">go to bots list</button>
      </div>
    </div>
  </div>
</template>

<script>
import { Token } from "../main.js";
export default {
  name: "landing",
  methods: {
    sendToLogin() {
      window.location.href =
        "https://gateway.cycloptux.com/auth/discord/login?redirect_uri=https://dashboard.cycloptux.com/";
    },
    setNewToken(newToken){
        this.$cookies.remove("tk");
        this.$cookies.set("tk", newToken, "2d"); //per ora cablo 2 giorni
        Token.token = newToken;
        this.$router.push("botSel");
    },
    callGetToken(code, callback) {
      $.ajax({
        url: "https://gateway.cycloptux.com/auth/discord/token",
        type: "GET",
        data: "{}",
        dataType: "json",
        headers: { Authorization: "Bearer " + code }
      }).done(data => {
        callback(data.access_token);
      });
    },
    callCheck(checkableToken, callback) {
      $.ajax({
        url: "https://gateway.cycloptux.com/auth/discord/token/check",
        type: "GET",
        data: "{}",
        dataType: "json",
        headers: { Authorization: "Bearer " + checkableToken }
      }).done(data => {
        callback(checkableToken, data.is_valid);
      });
    },
    checkedCookieToken(checkedToken, result) {
      if (result) {
        Token.token = checkedToken;
        this.$cookies.remove("tk"); //aggiorno comunque il cookie per essere sicuro sia tutto in ordine
        this.$cookies.set("tk", checkedToken, "2d"); //per ora cablo 2 giorni
        this.$router.push("botSel");
      } else {
        this.$cookies.remove("tk");
        this.sendToLogin();
      }
    },
    checkedCookieVariable(checkedToken, result) {
      if (result) {
        this.$cookies.remove("tk");
        this.$cookies.set("tk", checkedToken, "2d"); //per ora cablo 2 giorni
        this.$router.push("botSel");
      } else {
        Token.token = "";
        this.$cookies.remove("tk");
        this.sendToLogin();
      }
    },
    startAuth() {
      if (Token.token == "") {
        if (this.$cookies.isKey("tk")) {
          this.callCheck(this.$cookies.get("tk"), this.checkedCookieToken);
        } else {
          if (this.$route.query.c == null) {
            this.sendToLogin();
          } else {
            this.callGetToken(this.$route.query.c, this.setNewToken)
          }
        }
      } else {
        this.callCheck(Token.token, this.checkedCookieVariable);
      }
    }
  }
};
</script>
