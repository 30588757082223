<template>
  <el-select v-model="selServ" placeholder="Select server" no-data-text="No servers founded">
    <el-option v-for="server in servers" :key="server.id" :label="server.name" :value="server.id">
      <span style="float: left">{{ server.name }}</span>
      <img
        :src="'https://cdn.discordapp.com/icons/' + server.id + '/' + server.icon + '.png'"
        style="height:28px;width:auto;float:right;padding-top:3px;"
        @error="imageLoadOnError"
      />
    </el-option>
  </el-select>
</template>


<script>
import { Token } from "../main.js";
export default {
  name: "ddlServers",
  components: {},
  data() {
    return {
      servers: []
    };
  },
  props: {},
  methods: {
    imageLoadOnError(e) {
      e.target.src = require("../assets/error.png");
    }
  },
  computed: {
  selServ: {
    get () {
      return this.$store.getters.server_selected_id
    },
    set (value) {
      this.$store.commit('changeServer', this.servers.find(function(element) {return element.id == value;}))
    }
  }
  },
  mounted: function() {
    $.ajax({
      url:
        "https://gateway.cycloptux.com/api/v1/bots/" +
        this.$store.getters.bot_selected_id +
        "/servers",
      type: "GET",
      data: "{}",
      dataType: "json",
      headers: { Authorization: "Bearer " + Token.token }
    }).done(data => {
      if(jQuery.isEmptyObject(this.$store.getters.server_selected) && data.servers.length > 0){
        this.$store.commit('changeServer', data.servers[0])
      }
      this.servers = data.servers;
    });
  }
};
</script>
<style scoped>
</style>