import Vue from 'vue'
import Router from 'vue-router'
import landing from './views/landing.vue'
import modulesList from './views/modulesList.vue'
import logs from './views/logs.vue'
import dash from './views/dash.vue'
import test from './views/test.vue'
import botSel from './views/botSel.vue'
import forms from './views/forms.vue'
import form from './views/form.vue'



Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'landing',
      component: landing
    },
    {
      path: '/botSel',
      name: 'botSel',
      component: botSel,
      props: true
    },
    {
      path: '/dash',
      name: 'dash',
      component: dash,
      children: [
        {
          path: '/modulesList',
          name: 'modulesList',
          component: modulesList,
        },
        {
          path: '/logs',
          name: 'logs',
          component: logs,
        },
        {
          path: '/forms',
          name: 'forms',
          component: forms,
        },
        {
          path: '/form',
          name: 'form',
          component: form,
          props:true,
        }
      ]
    },
    {
      path: '/test',
      name: 'test',
      component: test,
    }
  ],
       mode: 'history'
})
