<template>
  <el-container>
    <el-aside width="auto">
        <menuLeft>
        </menuLeft>
    </el-aside>
    <el-main class="mainDashContent">
         <router-view></router-view>
    </el-main>
  </el-container>
</template>
<script>
import menuLeft from "@/components/menuLeft.vue";
import { Token } from "../main.js";
export default {
  components: {
    menuLeft
  },
  data() {
    return {
      
    };
  },
  mounted: function() {
    
  }
};
</script>
<style scoped>
.mainDashContent {
 background-color: #99AAB5;
 height: 100vh;
 overflow-y: scroll;
}
</style>