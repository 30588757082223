<template>
<el-col :span="24">
    <el-card shadow="never" v-bind:style="{ 'border-left': '4px solid ' + form.color_hex}">
      <el-row>
      <el-col :span="8" style="text-align:left; padding-top:10px;">{{form.title}}</el-col>
      <el-col :span="8" style="text-align:center;">
        <img v-if="!form.trello_integration" src="~@/assets/icons/discord.svg" class="iconImg"/>
        <img v-if="form.trello_integration" src="~@/assets/icons/trello.svg" class="iconImg" />
        </el-col>
      <el-col :span="8" style="text-align:right;"><router-link :to="{ name: 'form',  params: {formId: form.workflow_id }}"><el-button type="info" plain>Edit</el-button></router-link></el-col>
      </el-row>
    </el-card>
</el-col>
</template>

<script>
export default {
 name: "formForList",
 props: {
    form: Object
  }
};
</script>
<style scoped>
.iconImg{
  height: 36px;
}
</style>