<template>
<el-col :span="2" class="botforsel" >
<div @click="clickOnBot">
<img style="width: 100%; height: auto" :src="'https://cdn.discordapp.com/avatars/' + bot.id + '/' + bot.icon + '.png'" @error="imageLoadOnError"/>
<h3>{{bot.name}}</h3>
</div>
</el-col>
</template>
<script>

export default {
  name: 'botForSel',
  components: {
  },
  props: {
    bot: {
      type: Object,
      required: true
    }
  },
  methods: {
      imageLoadOnError (e) {
        e.target.src = require("../assets/error(big).png")
      },
      clickOnBot(){
          this.$store.commit('changeBot', this.bot)
          this.$router.push('dash')
      }
    }
}
</script>
<style scoped>
.botforsel{
    color:#fff;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
}
</style>