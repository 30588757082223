<template>
  <el-row>
    <module v-for="module in modules" v-bind:module="module" :key="module.name"/>
  </el-row>
</template>
<script>
import module from "@/components/module.vue";
import { Token } from "../main.js";
export default {
  components: {
    module
  },
  data() {
    return {
      modules: []
    };
  },
  mounted: function() {
    $.ajax({
      url: "https://gateway.cycloptux.com/api/v1/bots/" + this.$store.getters.bot_selected_id + "/servers/" + this.$store.getters.server_selected_id + "/modules",
      type: "GET",
      data: "{}",
      dataType: "json",
      headers: { Authorization: "Bearer " + Token.token }
    }).done(data => {
      this.modules = data.modules;
    });
  }
};
</script>

<style scoped>

</style>